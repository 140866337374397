
/*
    Custom.scss
*/

/* #region Search */
input[type="search"]:focus {
    color:blue!important;
}
/* #region Search */

/* #region Form */
div.filters {
    label {
        /* labels into table filters */
        font-size: .8em;
        margin-right: .4em!important;
        text-transform: capitalize;
    }
    input[type="date"].form-control {
        max-width: 160px;
    }
    input[type="month"].form-control {
        max-width: 190px;
    }
    select.form-control {
        max-width: 160px!important;
    }
}

/* #endregion Form */

/* #region Swal */
.swal-wide{
    width:850px!important;
}
/* #endregion Swal */

.gap-1 {
    gap: .3rem;
}

.gap-2 {
    gap: .6rem;
}

.gap-3 {
    gap: .9rem;
}
