// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~admin-lte/dist/css/adminlte.css';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// sweet alert
@import 'sweetalert2/src/sweetalert2.scss';
// import 'sweetalert2/src/sweetalert2.scss'

// Custom CSS
@import 'custom';

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.blue {color: $blue;}
.indigo {color: $indigo;}
.purple {color: $purple;}
.pink {color: $pink;}
.red {color: $red;}
.orange {color: $orange;}
.yellow {color: $yellow;}
.green {color: $green;}
.teal {color: $teal;}
.cyan {color: $cyan;}
.gray {color: $gray;}
.light_gray {color: $light_gray;}
.facebook_gray {color: $facebook_gray;}

.btn-green, .btn-green:focus, .btn-green:active {
    background-color: $green!important;
    border-color: #38c172!important;
}
.btn-green:hover {
    background-color: #40da81;
    border-color: #40da81!important;
}

/* text classes */
.capital {
    text-transform: capitalize;
}
.lower {
    text-transform: lowercase;
}
.upper {
    text-transform: uppercase;
}

img.brand-image.header {
    max-width: 48px;
}
// .content-header{
//   padding: 10px 0.5rem !important;
// }
